import React from "react";

import Layout from "../../components/layout";
import { CheckList, CheckListItem } from "../../components/CheckList";
import { CodePreview } from "../../components/docs/CodePreview";
import EmailTemplateSmallBox from "../../components/EmailTemplateSmallBox";

import welcomeEmailImage from "../../images/email-templates/email-template-welcome.jpg";
import ssoEmailImageEsai from "../../images/email-templates/email-template-sso-esai.jpg";
import ssoEmailImageRobolly from "../../images/email-templates/email-template-sso-robolly.jpg";
import ssoEmailImageElephanty from "../../images/email-templates/email-template-sso-elephanty.jpg";
import ssoEmailImageDoggify from "../../images/email-templates/email-template-sso-doggify.jpg";
import accountActivationEmailImage from "../../images/email-templates/email-template-account-activation.jpg";
import passwordResetEmailImage from "../../images/email-templates/email-template-password-reset.jpg";
import ssoEmailImage from "../../images/email-templates/email-template-sso.jpg";
import receiptEmailImage from "../../images/email-templates/email-template-receipt.jpg";
import paymentFailedEmailImage from "../../images/email-templates/email-template-payment-failed.jpg";
import trialExpiredEmailImage from "../../images/email-templates/email-template-trial-ended.jpg";

export default () => {
	return (
		<Layout
			pageMeta={{
				title: "Single sign-on email – Well-tested & Ready-to-use template",
				description:
					"Use a well-tested welcome email template. Mobile-responsive, customizable, dark mode optimized, and ready to send via API from your app or service.",
			}}>
			<div>
				<section className="aboveFold mb-120">
					<div className="container">
						<div className="templates-header">
							<div className="text-left">
								<h1>Single sign-on (SSO) email template</h1>

								<p className="">
									Offer passwordless login and send single sign-on (SSO) emails.
									With Sidemail, you can use this email template and powerful
									email API to deliver emails with ease.
								</p>

								<CheckList className="mb-30">
									<CheckListItem>Mobile-responsive</CheckListItem>
									<CheckListItem>Supports all email clients</CheckListItem>
									<CheckListItem>White-label</CheckListItem>
									<CheckListItem>Dark-mode</CheckListItem>
								</CheckList>

								<a
									className="button button-primary mb-40"
									href="https://client.sidemail.io/register">
									Try Sidemail free & Get this template
								</a>
							</div>

							<img
								src={ssoEmailImage}
								loading="lazy"
								alt="Single-sign-on email template"
								className="templates-image"
							/>
						</div>
					</div>
				</section>

				<section className="container maxWidth-820 text-center mb-150">
					<h2 className="title-large">Ready to send via API</h2>

					<p className="maxWidth-680 text-large text-center itemCenter mb-50">
						Send emails, like this SSO email template, from your application in
						just a few minutes — Sidemail makes it incredibly easy to create,
						deliver, maintain, and analyze beautiful transactional emails.{" "}
						<a href="/email-sending-api/">Learn more about email sending API</a>
						.
					</p>

					<CodePreview language="javascript">
						{`
await sidemail.sendEmail({
toAddress: "user@email.com",
fromName: "Startup name",
fromAddress: "your@startup.com",
templateName: "Single sign-on",
templateProps: {
    "project_name": "Doggify",
    "url": "https://your.app/sso?token=123"
    },
});
`}
					</CodePreview>
				</section>

				<section className="container text-center mb-150">
					<h2 className="title-large">Customize design to fit your brand</h2>

					<p className="maxWidth-680 text-large text-center itemCenter mb-50">
						With Sidemail, you get a beautiful email design that you can fully
						customize to fit your brand — upload your logo, pick your brand
						colors and font, and if you fancy, turn on dark mode.
					</p>

					<div className="templatePreviewContainer-4">
						<div className="templatePreview-4">
							<img
								src={ssoEmailImageEsai}
								alt="Single sign-on email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={ssoEmailImageDoggify}
								alt="Single sign-on email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={ssoEmailImageRobolly}
								alt="Single sign-on email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={ssoEmailImageElephanty}
								alt="Single sign-on email template image"
								loading="lazy"
							/>
						</div>
					</div>
				</section>

				<section className="mb-140">
					<div className="container maxWidth-880">
						<div className="grid grid--alignCenter mb-30">
							<div className="grid-cell grid-cell--notFluid">
								<h2 className="m-0">Common questions</h2>
							</div>

							<div className="grid-cell">
								<hr className="separator m-0" />
							</div>
						</div>

						<p className="u-text-bold mb-4">
							Do I need to work with HTML in order to use this welcome email
							template?
						</p>
						<p className="text-light mt-0 mb-50">
							No, you don't. We provide you with email templates that are
							well-tested and ready to send, so you don't need to be familiar
							with coding HTML emails.
						</p>

						<p className="u-text-bold mb-4">
							Is this template available in dark mode?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, all templates, including the welcome email, are available in
							both light and dark modes.
						</p>

						<p className="u-text-bold mb-4">
							May I customize the design of the welcome email template?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, you can fully customize the design – upload your logo, pick
							colors and fonts, and turn dark mode on or off.
						</p>

						<p className="u-text-bold mb-4">
							Is this email template mobile responsive?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, all templates, including this welcome email, are mobile
							responsive and well-tested, so they won't break in any inbox
							(including Outlook!).
						</p>

						<p className="u-text-bold mb-4">
							How long does it take to get started?
						</p>
						<p className="text-light mt-0 mb-50">
							Integrating transactional emails, like this welcome email
							template, is extremely fast and straightforward with Sidemail. The
							whole integration process takes under 30 minutes.{" "}
							<a href="https://client.sidemail.io/register">
								Start your free trial here
							</a>
							.
						</p>

						<p className="u-text-bold mb-4">
							May I create my own email template from scratch?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, Sidemail features{" "}
							<a href="/no-code-email-editor/"> No-code email editor</a> that
							will help you craft professionally-looking, responsive email
							templates. You can also use Sidemail's AI email generator to craft
							the email copy.
						</p>
					</div>
				</section>

				<section className="container maxWidth-880 text-center mb-50">
					<h2 className="title-large mb-50">More email templates...</h2>

					<div className="moreTemplatesWrapper">
						<EmailTemplateSmallBox
							title="Password reset email"
							href="/transactional-email-templates/password-reset-email-template/"
							src={passwordResetEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Welcome email"
							href="/transactional-email-templates/welcome-email-template/"
							src={welcomeEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Account activation email"
							href="/transactional-email-templates/account-activation-email-template/"
							src={accountActivationEmailImage}
						/>
					</div>

					<div className="moreTemplatesWrapper mb-40">
						<EmailTemplateSmallBox
							title="Subscription payment failed"
							href="/transactional-email-templates/payment-failed-email-template/"
							src={paymentFailedEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Receipt email"
							href="/transactional-email-templates/receipt-email-template/"
							src={receiptEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Trial expired email"
							href="/transactional-email-templates/trial-expired-email-template/"
							src={trialExpiredEmailImage}
						/>
					</div>

					<a href="/transactional-email-templates/">
						Browse all email templates →
					</a>
				</section>
			</div>
		</Layout>
	);
};
