import React from "react";

import Layout from "../components/layout";
import StarRating from "../components/star-rating";
import TestimonialAutor from "../components/testimonial-autor";

import IconShield from "../images/inlinesvgs/icons/shield-check.svg";
import IconDev from "../images/inlinesvgs/icons/dev.svg";
import IconStar from "../images/inlinesvgs/icons/star.svg";
import IconHandshake from "../images/inlinesvgs/icons/handshake.svg";

import nickPhoto from "../images/testimonial-quicka-nick.jpg";
import julienPhoto from "../images/testimonial-tpop-julien.jpeg";
import hariPhoto from "../images/testimonial-visalist-hari.jpg";
import rikPhoto from "../images/testimonial-doka-rik.jpg";

export default () => {
	const ValuesBox = ({ title, text, icon }) => {
		return (
			<div className="valuesWrapper">
				<div className="valuesIcon">{icon}</div>
				<div className="valuesText">
					<div className="text-dark mb-8">
						<strong>{title}</strong>
					</div>
					<div className="text-small">{text}</div>
				</div>
			</div>
		);
	};

	return (
		<Layout
			pageMeta={{
				title: "About us – Sidemail",
			}}>
			<main id="about-us">
				<section className="aboveFold">
					<div className="container">
						<header>
							<h1>
								<div className="text-xsmall uptitle mb-20">About Sidemail</div>
								<div>
									Helping businesses <strong>worldwide</strong> to deliver
									emails to their customers <strong>reliably</strong> and{" "}
									<strong>with ease</strong>.
								</div>
							</h1>
						</header>
					</div>
				</section>

				<section>
					<div className="dippedGradientBackground mb-150">
						<div className="container">
							<div className="box-whiteWithBorder text-center">
								<h2 className="text-xsmall uptitle mb-50">Core values</h2>
								<div className="valuesGrid text-left">
									<ValuesBox
										icon={<IconShield />}
										title="Uncompromising quality"
										text="Sidemail is built upon Amazon’s AWS robust infrastructure in combination with custom in‑house configurations it ensures highly reliable email delivery that scales extremely well."
									/>

									<ValuesBox
										icon={<IconDev />}
										title="First‑principles simplicity"
										text="Dealing with emails is exhausting. We handle for you all the tricky email stuff and provide you with the best practices and delivery possible. Simple instructions. Quick setup."
									/>

									<ValuesBox
										icon={<IconStar />}
										title="Best in class customer care"
										text="Founders themselves answers more than 95% of all support emails to ensure you get the best possible personal help. Satisfied customer is our highest priority."
									/>

									<ValuesBox
										icon={<IconHandshake />}
										title="Individual approach"
										text="We've been continually working side‑by‑side with our customers, developing additional features based on their specific needs while improving whole product for everyone."
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="container maxWidth-550 mb-90">
					<h2 className="text-xsmall uptitle text-center mb-50">
						Background story
					</h2>
					<div className="storyText text-center">
						<div className="mb-20">
							<i>
								"When we needed Sidemail, it didn’t exist. Integrating
								transactional emails into an app felt like reinventing the
								wheel. To sent product updates, it was a nonstop struggle with
								unnecessarily complicated marketing tools.
								<br />
								So, we decided to fix that and built Sidemail."
							</i>
						</div>
						<div className="text-small text-light">
							– Patrik & Kristyna, Sidemail founders
						</div>
					</div>
				</section>

				<section className="container mb-170">
					<div className="box-lightBackground statsBox">
						<div className="statsWrapper">
							<strong>2018</strong>
							<div className="text-small text-light">Founded in</div>
						</div>

						<div className="statsWrapper">
							<strong>EU</strong>
							<div className="text-small text-light">Based business</div>
						</div>

						<div className="statsWrapper">
							<strong>100%</strong>
							<div className="text-small text-light">Bootstraped</div>
						</div>

						<div className="statsWrapper">
							<strong>5/5</strong>
							<div className="text-small text-light">Customer rated</div>
						</div>
					</div>
				</section>

				<section className="lightBlueBg">
					<div className="container">
						<h2 className="text-xsmall uptitle text-center mb-50">
							Customer reviews
						</h2>

						<div className="testimonialGrid mb-70">
							<div className="testimonialBoxSmall">
								<div className="mb-10">
									<StarRating />
								</div>

								<h3 className="testimonialTitle text-dark">
									Nothing could beat Sidemail
								</h3>

								<p className="text-medium line-height-large itemCenter">
									“We were absolutely blown away by how awesome the Sidemail
									offering has been for us at Quicka. We looked at rolling our
									own and leveraging existing providers but nothing could beat
									Sidemail for simplicity and ease of use for beautiful
									transactional emails. Thanks Patrik and the team!“
								</p>

								<TestimonialAutor
									name="Nick Glynn"
									position={
										<span className="text-light">
											Co-founder and CTO of{" "}
											<a href="https://www.rapidpaylegal.com.au/">
												RapidPay Group
											</a>
										</span>
									}
									imgSrc={nickPhoto}
									imgAlt="Nick Glynn photo"
								/>
							</div>

							<div className="testimonialBoxSmall">
								<div className="mb-10">
									<StarRating />
								</div>

								<h3 className="testimonialTitle text-dark">
									More complete service than anything else on the market
								</h3>

								<p className="text-medium line-height-large itemCenter">
									“Great service, very easy to use but with many options. More
									complete service than anything else on the market, with a
									quick learning curve: a dream for our use. The Sidemail team
									is super nice and professional, always listening, it's great.“
								</p>

								<TestimonialAutor
									name="Julien Boulogne"
									position={
										<span className="text-light">
											Founder & CEO of{" "}
											<a href="https://www.tpop.com/en/">TPOP</a>
										</span>
									}
									imgSrc={julienPhoto}
									imgAlt="Julien Boulogne photo"
								/>
							</div>

							<div className="testimonialBoxSmall">
								<div className="mb-10">
									<StarRating />
								</div>
								<h3 className="testimonialTitle text-dark">
									Exactly what I needed
								</h3>
								<p className="text-medium line-height-large itemCenter">
									“My app messages sent with PHP mail() ended up in spam. I’m no
									email expert, and Sidemail seemed to do exactly what I needed,
									so I replaced mail() with a code snippet from Sidemail, and it
									just worked. I had some trouble setting up DNS, so I contacted
									the Sidemail support and quickly got a helpful response that
									resolved my issue.”
								</p>

								<TestimonialAutor
									name="Rik Schennink"
									position={
										<span className="text-light">
											Founder of <a href="https://pqina.nl/pintura/">PQINA</a>
										</span>
									}
									imgSrc={rikPhoto}
									imgAlt="Rik Schennink photo"
								/>
							</div>

							<div className="testimonialBoxSmall">
								<div className="mb-10">
									<StarRating />
								</div>

								<h3 className="testimonialTitle text-dark">
									By far the easiest email service
								</h3>

								<p className="text-medium line-height-large itemCenter">
									“Sidemail is by far the easiest email service I have used. I
									was up and sending welcome emails in 10 mins. That’s it. It
									can send transactional, marketing, and newsletter emails. It
									supports dark mode from get-go and is ready for different
									email clients. All you have to do is upload two different
									logos for light and dark themes.“
								</p>

								<TestimonialAutor
									name="Hari Krishna Dalipudi"
									position={
										<span className="text-light">
											Founder of <a href="https://visalist.io/">Visalist</a>
										</span>
									}
									imgSrc={hariPhoto}
									imgAlt="Hari Krishna Dalipudi photo"
								/>
							</div>
						</div>

						<div className="text-center">
							<a href="/customer-reviews/">Read more customer reviews →</a>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
