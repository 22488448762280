import React from "react";

import Layout from "../../components/layout";
import { CheckList, CheckListItem } from "../../components/CheckList";
import { CodePreview } from "../../components/docs/CodePreview";
import EmailTemplateSmallBox from "../../components/EmailTemplateSmallBox";

import passwordResetEmailImage from "../../images/email-templates/email-template-password-reset.jpg";
import canceledSubscriptionEmailImageEsai from "../../images/email-templates/email-template-canceled-subscription-esai.jpg";
import canceledSubscriptionEmailImageRobolly from "../../images/email-templates/email-template-canceled-subscription-robolly.jpg";
import canceledSubscriptionEmailImageElephanty from "../../images/email-templates/email-template-canceled-subscription-elephanty.jpg";
import canceledSubscriptionEmailImageDoggify from "../../images/email-templates/email-template-canceled-subscription-doggify.jpg";
import accountActivationEmailImage from "../../images/email-templates/email-template-account-activation.jpg";
import ssoEmailImage from "../../images/email-templates/email-template-sso.jpg";
import receiptEmailImage from "../../images/email-templates/email-template-receipt.jpg";
import paymentFailedEmailImage from "../../images/email-templates/email-template-payment-failed.jpg";
import canceledsubscriptionEmailImage from "../../images/email-templates/email-template-canceled-subscription.jpg";
import welcomeEmailImage from "../../images/email-templates/email-template-welcome.jpg";

export default () => {
	return (
		<Layout
			pageMeta={{
				title:
					"Canceled subscription email – Well-tested & Ready-to-use template",
				description:
					"Use a well-tested canceled subscription email template. Mobile-responsive, customizable, dark mode optimized, and ready to send via API from your app or service.",
			}}>
			<div>
				<section className="aboveFold mb-120">
					<div className="container">
						<div className="templates-header">
							<div className="text-left">
								<h1>Canceled subscription email template</h1>

								<p className="">
									Send a confirmation email after users cancel their
									subscription. This email template can also be used to ask for
									feedback, say thank you, and foster good relationships.
								</p>

								<CheckList className="mb-30">
									<CheckListItem>Mobile-responsive</CheckListItem>
									<CheckListItem>Supports all email clients</CheckListItem>
									<CheckListItem>White-label</CheckListItem>
									<CheckListItem>Dark-mode</CheckListItem>
								</CheckList>

								<a
									className="button button-primary mb-40"
									href="https://client.sidemail.io/register">
									Try Sidemail free & Use this template
								</a>
							</div>

							<img
								src={canceledsubscriptionEmailImage}
								loading="lazy"
								alt="Canceled subscription email template"
								className="templates-image"
							/>
						</div>
					</div>
				</section>

				<section className="container maxWidth-820 text-center mb-150">
					<h2 className="title-large">Ready to send via API</h2>

					<p className="maxWidth-680 text-large text-center itemCenter mb-50">
						Send emails, like this canceled subscription email, from your
						application in just a few minutes — Sidemail makes it incredibly
						easy to create, deliver, maintain, and analyze beautiful
						transactional emails.{" "}
						<a href="/email-sending-api/">Learn more about email sending API</a>
						.
					</p>

					<CodePreview language="javascript">
						{`
await sidemail.sendEmail({
toAddress: "user@email.com",
fromName: "Startup name",
fromAddress: "your@startup.com",
templateName: "Canceled subscription",
templateProps: {
    "my_name": "John"
    },
});
`}
					</CodePreview>
				</section>

				<section className="container text-center mb-150">
					<h2 className="title-large">Customize design to fit your brand</h2>

					<p className="maxWidth-680 text-large text-center itemCenter mb-50">
						With Sidemail, you get a beautiful email design that you can fully
						customize to fit your brand — upload your logo, pick your brand
						colors and font, and if you fancy, turn on dark mode.
					</p>

					<div className="templatePreviewContainer-4">
						<div className="templatePreview-4">
							<img
								src={canceledSubscriptionEmailImageEsai}
								alt="Canceled subscription email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={canceledSubscriptionEmailImageDoggify}
								alt="Canceled subscription email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={canceledSubscriptionEmailImageRobolly}
								alt="Canceled subscription email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={canceledSubscriptionEmailImageElephanty}
								alt="Canceled subscription email template image"
								loading="lazy"
							/>
						</div>
					</div>
				</section>

				<section className="mb-140">
					<div className="container maxWidth-880">
						<div className="grid grid--alignCenter mb-30">
							<div className="grid-cell grid-cell--notFluid">
								<h2 className="m-0">Common questions</h2>
							</div>

							<div className="grid-cell">
								<hr className="separator m-0" />
							</div>
						</div>

						<p className="u-text-bold mb-4">
							Do I need to work with HTML in order to use this canceled
							subscription email template?
						</p>
						<p className="text-light mt-0 mb-50">
							No, you don't. We provide you with email templates that are
							well-tested and ready to send, so you don't need to be familiar
							with coding HTML emails.
						</p>

						<p className="u-text-bold mb-4">
							Is this template available in dark mode?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, all templates, including the canceled subscription email, are
							available in both light and dark modes.
						</p>

						<p className="u-text-bold mb-4">
							May I customize the design of the canceled subscription email
							template?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, you can fully customize the design – upload your logo, pick
							colors and fonts, and turn dark mode on or off.
						</p>

						<p className="u-text-bold mb-4">
							Is this email template mobile responsive?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, all templates, including this canceled subscription email,
							are mobile responsive and well-tested, so they won't break in any
							inbox (including Outlook!).
						</p>

						<p className="u-text-bold mb-4">
							How long does it take to get started?
						</p>
						<p className="text-light mt-0 mb-50">
							Integrating transactional emails, like this canceled subscription
							email, is extremely fast and straightforward with Sidemail. The
							whole integration process takes under 30 minutes.{" "}
							<a href="https://client.sidemail.io/register">
								Start your free trial here
							</a>
							.
						</p>

						<p className="u-text-bold mb-4">
							May I create my own email template from scratch?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, Sidemail features{" "}
							<a href="/no-code-email-editor/"> No-code email editor</a> that
							will help you craft professionally-looking, responsive email
							templates. You can also use Sidemail's AI email generator to craft
							the email copy.
						</p>
					</div>
				</section>

				<section className="container maxWidth-880 text-center mb-50">
					<h2 className="title-large mb-50">More email templates...</h2>

					<div className="moreTemplatesWrapper">
						<EmailTemplateSmallBox
							title="Welcome email"
							href="/transactional-email-templates/welcome-email-template/"
							src={welcomeEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Password reset email"
							href="/transactional-email-templates/password-reset-email-template/"
							src={passwordResetEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Single sign-on email"
							href="/transactional-email-templates/sso-email-template/"
							src={ssoEmailImage}
						/>
					</div>

					<div className="moreTemplatesWrapper mb-40">
						<EmailTemplateSmallBox
							title="Account activation email"
							href="/transactional-email-templates/account-activation-email-template/"
							src={accountActivationEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Subscription payment failed"
							href="/transactional-email-templates/payment-failed-email-template/"
							src={paymentFailedEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Receipt email"
							href="/transactional-email-templates/receipt-email-template/"
							src={receiptEmailImage}
						/>
					</div>

					<a href="/transactional-email-templates/">
						Browse all email templates →
					</a>
				</section>
			</div>
		</Layout>
	);
};
