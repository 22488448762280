import React from "react";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";

import accountActivationEmailImage from "../images/email-templates/email-template-account-activation.jpg";
import welcomeEmailImage from "../images/email-templates/email-template-welcome.jpg";
import passwordResetEmailImage from "../images/email-templates/email-template-password-reset.jpg";
import ssoEmailImage from "../images/email-templates/email-template-sso.jpg";
import receiptEmailImage from "../images/email-templates/email-template-receipt.jpg";
import paymentFailedEmailImage from "../images/email-templates/email-template-payment-failed.jpg";
import canceledsubscriptionEmailImage from "../images/email-templates/email-template-canceled-subscription.jpg";
import trialExpiredEmailImage from "../images/email-templates/email-template-trial-ended.jpg";

export default () => {
	const EmailTemplateContainer = ({ title, href, src, description }) => {
		return (
			<a
				className="emailTemplateContainer box-whiteWithBorder mb-20"
				href={href}>
				<div className="emailTemplateImageWrapper">
					<img
						src={src}
						loading="lazy"
						alt={title}
						className="emailTemplateImage"
					/>
				</div>
				<div className="emailTemplateText">
					<h2 className="">{title}</h2>

					<div className="emailTemplateDescription text-small text-mediumDark verticalList mb-15">
						{description}
					</div>
				</div>
			</a>
		);
	};

	return (
		<Layout
			pageMeta={{ title: "Transactional email templates", description: "" }}>
			<div>
				<section className="aboveFold">
					<div className="container">
						<h1>
							<div className="text-xsmall uptitle mb-20 text-light">
								Transactional email templates
							</div>
							<div className="maxWidth-900">
								Use well‑tested email templates
							</div>
						</h1>

						<p className="subtitle">
							Sidemail provides you with transactional email templates for any
							scenario and functionality to let you hit the ground running — in
							minutes. All templates are well‑tested and optimized to look great
							in any inbox. Just select the templates you need and start
							sending.
						</p>
					</div>
				</section>

				<section className="container maxWidth-820 mb-150">
					<EmailTemplateContainer
						title="Password reset email"
						href="/transactional-email-templates/password-reset-email-template/"
						src={passwordResetEmailImage}
						description="Deliver password reset email in case users need to change their password."
					/>

					<EmailTemplateContainer
						title="Welcome email"
						href="/transactional-email-templates/welcome-email-template/"
						src={welcomeEmailImage}
						description="Send welcome emails to greet users in your application or service, guide them through their first steps and offer help if needed."
					/>

					<EmailTemplateContainer
						title="Account activation email"
						href="/transactional-email-templates/account-activation-email-template/"
						src={accountActivationEmailImage}
						description="Send account activation email to verify your users' email addresses and activate their accounts."
					/>

					<EmailTemplateContainer
						title="Single sign-on email (SSO)"
						href="/transactional-email-templates/sso-email-template/"
						src={ssoEmailImage}
						description="Offer passwordless login and send SSO emails."
					/>

					<EmailTemplateContainer
						title="Receipt email"
						href="/transactional-email-templates/receipt-email-template/"
						src={receiptEmailImage}
						description="Send your user an email with a receipt, invoice, order confirmation, or subscription activation. "
					/>

					<EmailTemplateContainer
						title="Subscription payment failed email"
						href="/transactional-email-templates/payment-failed-email-template/"
						src={paymentFailedEmailImage}
						description="Notify users about issues with payment."
					/>

					<EmailTemplateContainer
						title="Canceled subscription email"
						href="/transactional-email-templates/canceled-subscription-email-template/"
						src={canceledsubscriptionEmailImage}
						description="Send follow up email on canceled subscription."
					/>

					<EmailTemplateContainer
						title="Trial expired email"
						href="/transactional-email-templates/trial-expired-email-template/"
						src={trialExpiredEmailImage}
						description="Send notification email when trial is ending and increase conversion rates."
					/>
				</section>

				<section className="container maxWidth-820 text-center mb-150">
					<h2 className="title-large">
						Didn't find the right email template for you?
					</h2>

					<p className="text-large mb-40">
						Sidemail features{" "}
						<a href="/no-code-email-editor/"> No-code email editor</a> – the
						simplest way to craft professionally‑looking, responsive email
						templates that won't break. Combine it with Sidemail's AI email
						generator, which will help you craft the email copy, and you're
						ready to start sending in style within a few moments.
					</p>

					<div className="videoContainer">
						<video loop autoPlay playsInline muted height={300}>
							<source src="/assets/editor.mp4" type="video/mp4" />
						</video>
					</div>
				</section>

				<CtaSection />
			</div>
		</Layout>
	);
};
