// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-docs-template-js": () => import("./../src/docs-template.js" /* webpackChunkName: "component---src-docs-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anti-spam-policy-js": () => import("./../src/pages/anti-spam-policy.js" /* webpackChunkName: "component---src-pages-anti-spam-policy-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-amazon-ses-alternatives-js": () => import("./../src/pages/articles/amazon-ses-alternatives.js" /* webpackChunkName: "component---src-pages-articles-amazon-ses-alternatives-js" */),
  "component---src-pages-articles-best-transactional-email-platform-js": () => import("./../src/pages/articles/best-transactional-email-platform.js" /* webpackChunkName: "component---src-pages-articles-best-transactional-email-platform-js" */),
  "component---src-pages-articles-brevo-alternatives-js": () => import("./../src/pages/articles/brevo-alternatives.js" /* webpackChunkName: "component---src-pages-articles-brevo-alternatives-js" */),
  "component---src-pages-articles-dark-mode-in-html-email-js": () => import("./../src/pages/articles/dark-mode-in-html-email.js" /* webpackChunkName: "component---src-pages-articles-dark-mode-in-html-email-js" */),
  "component---src-pages-articles-mailgun-alternatives-js": () => import("./../src/pages/articles/mailgun-alternatives.js" /* webpackChunkName: "component---src-pages-articles-mailgun-alternatives-js" */),
  "component---src-pages-articles-mandrill-alternatives-js": () => import("./../src/pages/articles/mandrill-alternatives.js" /* webpackChunkName: "component---src-pages-articles-mandrill-alternatives-js" */),
  "component---src-pages-articles-postmark-alternatives-js": () => import("./../src/pages/articles/postmark-alternatives.js" /* webpackChunkName: "component---src-pages-articles-postmark-alternatives-js" */),
  "component---src-pages-articles-sendgrid-alternatives-js": () => import("./../src/pages/articles/sendgrid-alternatives.js" /* webpackChunkName: "component---src-pages-articles-sendgrid-alternatives-js" */),
  "component---src-pages-articles-transactional-email-vs-marketing-email-js": () => import("./../src/pages/articles/transactional-email-vs-marketing-email.js" /* webpackChunkName: "component---src-pages-articles-transactional-email-vs-marketing-email-js" */),
  "component---src-pages-articles-what-is-email-api-js": () => import("./../src/pages/articles/what-is-email-api.js" /* webpackChunkName: "component---src-pages-articles-what-is-email-api-js" */),
  "component---src-pages-automated-email-sequences-js": () => import("./../src/pages/automated-email-sequences.js" /* webpackChunkName: "component---src-pages-automated-email-sequences-js" */),
  "component---src-pages-contact-profiles-js": () => import("./../src/pages/contact-profiles.js" /* webpackChunkName: "component---src-pages-contact-profiles-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-reviews-js": () => import("./../src/pages/customer-reviews.js" /* webpackChunkName: "component---src-pages-customer-reviews-js" */),
  "component---src-pages-email-marketing-js": () => import("./../src/pages/email-marketing.js" /* webpackChunkName: "component---src-pages-email-marketing-js" */),
  "component---src-pages-email-open-tracking-js": () => import("./../src/pages/email-open-tracking.js" /* webpackChunkName: "component---src-pages-email-open-tracking-js" */),
  "component---src-pages-email-sending-api-js": () => import("./../src/pages/email-sending-api.js" /* webpackChunkName: "component---src-pages-email-sending-api-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-no-code-email-editor-js": () => import("./../src/pages/no-code-email-editor.js" /* webpackChunkName: "component---src-pages-no-code-email-editor-js" */),
  "component---src-pages-press-js": () => import("./../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-subscribe-forms-js": () => import("./../src/pages/subscribe-forms.js" /* webpackChunkName: "component---src-pages-subscribe-forms-js" */),
  "component---src-pages-targeted-email-messages-js": () => import("./../src/pages/targeted-email-messages.js" /* webpackChunkName: "component---src-pages-targeted-email-messages-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transactional-email-templates-js": () => import("./../src/pages/transactional-email-templates.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-js" */),
  "component---src-pages-transactional-email-templates-account-activation-email-template-js": () => import("./../src/pages/transactional-email-templates/account-activation-email-template.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-account-activation-email-template-js" */),
  "component---src-pages-transactional-email-templates-canceled-subscription-email-template-js": () => import("./../src/pages/transactional-email-templates/canceled-subscription-email-template.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-canceled-subscription-email-template-js" */),
  "component---src-pages-transactional-email-templates-password-reset-email-template-js": () => import("./../src/pages/transactional-email-templates/password-reset-email-template.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-password-reset-email-template-js" */),
  "component---src-pages-transactional-email-templates-payment-failed-email-template-js": () => import("./../src/pages/transactional-email-templates/payment-failed-email-template.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-payment-failed-email-template-js" */),
  "component---src-pages-transactional-email-templates-receipt-email-template-js": () => import("./../src/pages/transactional-email-templates/receipt-email-template.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-receipt-email-template-js" */),
  "component---src-pages-transactional-email-templates-sso-email-template-js": () => import("./../src/pages/transactional-email-templates/sso-email-template.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-sso-email-template-js" */),
  "component---src-pages-transactional-email-templates-trial-expired-email-template-js": () => import("./../src/pages/transactional-email-templates/trial-expired-email-template.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-trial-expired-email-template-js" */),
  "component---src-pages-transactional-email-templates-welcome-email-template-js": () => import("./../src/pages/transactional-email-templates/welcome-email-template.js" /* webpackChunkName: "component---src-pages-transactional-email-templates-welcome-email-template-js" */)
}

